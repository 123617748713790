<template>
    <div id="container">
        <div id="loginContainer">
        <div class="pictureContainer">
            <img class="logo" src="https://cdn.protonrp.com/img/logo/prp_white_trans.png">
        </div>
        <div class="discordContainer">
            <form>
                <h1>WELCOME BACK! &#128075; </h1>
            <div class="formContainer">
                <div class="formDiv">
                    <p class="titleText">LITHIUM DASHBOARD AUTHORIZATION</p>
                    <br>
                    <p class="subText">Welcome to the Lithium Dashboard, this application is used to control in-game variables of the 'Lithium' script. Only staff members are allowed to sign in, if you encounter any issues while browsing this website please notify the ProtonRP Development team!</p>
                    <br>
                    <p class="subText">Current Version: {{ details }}</p>
                </div>
                </div>
                <div class="formButton">
                <button type="button" class="button" v-on:click="auth">
                <svg width="24" height="24" viewBox="0 0 24 24" class="discord_icon">
                    <image id="image0" width="24" height="24" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA+gAAAL6CAMAAACSFIzyAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABqlBMVEUAAAD////4+f/29v74
+f79/f/29/4AAP/4+f719v75+f/9/f/29v729/76+v/29v75+v/29/739/7+/v/7+//29v73+P78
/P/7+//39/79/f/7/P/29v73+P78/P/19v729/729/7+/v/29/73+P719v75+v/29v75+f/5+f/5
+f7+/v/19v78/P/29v76+//5+v/29/5VVf/4+f/9/v/8/P/8/f/5+v/19v74+f/29v74+P78/P/6
+v/6+//29v76+//29/75+f/7+//29v77/P/19v77/P/6+v/5+f75+f/4+P73+P729v73+P76+v/3
+P76+v/29/74+P729/74+P+AgP/+/v/29/76+//29v729/79/v/3+P77+//39/729v73+P79/f/2
9/729/739/719v719v78/f/29/719v729/729v75+f/29/77/P/4+P74+f/29v5Jbf9gYP9AgP9m
Zv/3+P729/729v729v5NZuZVcf9VYfNZZvJaafBea/JVY/FgYO9daPNbbe1VaupOYutdXehVZu5V
Vf9VZPD////+/v/+//+F/8CnAAAAi3RSTlMAob/g9P3pAfPeu6jP6/nV+OfJpa/Z8v377aeu1/H8
2OrLpM7u4ffiurz2/t2r0fq55gO9pqypt9f15POttrLjtND2sd+s2/u197jyw9vws+/46MHJvwKi
5fnUyv7C+sXcxv7sz8fa3/3N2cjl9cz8wL7WBwgEBcTS0tMKCRUUERMSEBYODA0LDwYh/Pj6IAAA
AAFiS0dEAf8CLd4AAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAHdElNRQflCw4XCwDnzwqNAAAymklE
QVR42u3d+VcUyZ428CyhoIRmExeKpRpxQSuLpVREcW+0XUA2d22xxaVxX257bXvu+87MfWfufe8g
//NUFVsBteQSEU9ExvP5pc/pPn1OxJPxJbMiIyMchySK7aipjS/l1KFboqv6fDzxxM6GGLolREE0
/tDUvLShGd0eTbWsJ/S9tW1XO7o5RN7t3rN339JWu9Ct0tP3LTF1JH/oRLeJqJqu7p7UUklxdNO0
9GPJrFI93Y3olhGVVr7GV7SgG6ijCnl19O7vQzePqFj7gYMVa3wFupUaOlQts8P9RzhLRzo42p92
qxd5XgbdVO3Ue8rNTXQPoFtKNusc7PFwI99Qj26wbtq8ZzfUu5+3dlJvuL/VT40XZNGN1swxn/nl
bu2ckSdlYsezQ76LvOAEuul6SQaIcDmdGUG3m6KvfX/S19P6Zkl087VyMnCOQ9lRdOMpujpPnV4O
XuQFvBkV6QkV5dDYKH+0k2gnBhNuyCLPO4Puh0bOho+zI3sO3QuKjs5TtWHv5OvOozujj9NiEh26
cBHdEzJf36XE9/CDccNP6A5pY1xgqs2Xr6C7QwY7mu0QWeQFF9Gd0oWgG/q61gxfvZF/XRn/78m9
SKM7pgkBv9C3WU4Mcn6OvGv8+bCUIi+4iO6dHhKS4r2avYbuGhmhoTcur8pzrqM7qIUrMiNu7eZn
b1TJiW45z+ub3ED3UgcTkkOOJ4+iu0iamkzKvZWvOYzuqAYGVATdmuGSY9qs82Zz+IHlFZdvOlOK
ot7XNo3uKmnjYjbE8vUAWtEdhgu+yj2AxC5OxVPugd1VWuV5w+hOo80oDnyID/FW6xtMKy/yvFl0
x8E6AZnHe8fR3SaIkX61D+zFhtGdxwryHboAbvIIuuek2Picmhn2Muy+pSNu6Gt6bqF7T8rcAPws
38LqifcmbPa1tzk5Z4E7+CpfsnvF+110+EtLQ9330CmQTJOyVlj7thsdBc59dPYFD1pY6xF1J9zW
RWJNoNPAQUe/jvf1CNqhU5XnWbt7XAadfDHWeqSM9grbCkqYg+hQUNDBbzXUjU6EhLihehmWR5au
1XqIzr2E9CN0KhTSSNZFj6Jy5tDZYPyCzr209GN0MBRYewa39s2Du+h8EG6jUy+vh1tHmyg2GPDY
JGX60REhaP2n1+3lZrKG2YX5WsUfdEgAd9CZV5Pqb0dnRF6dU7WtQUg/o4NSbx6duQfXn6BTIg80
/2Fe7Ds6K+WG0ZF71MOdZDV3QOGOUOENouNSTeIu2oLt+5UbyWrrIug75+CjCZ2YYrvRgfvS+hSd
F5XQeWEBPTL8O45OTa296Lz9embxt0d6emrCLPt2dh3mgNxwIqh4Bp0arTuZ1W8pu0cK39vGSlF6
nXrRaQeTsHqXEH08N+GVTTlJkUnkCrfv4o4Xgy9/yz5LJhLp1tmOjsX4L8tV/wwuu/H41Y6h1tZE
Yio5N5Z5+Gpy9IrwvwP16LAD2/e6XumQpm3GD6IHQUhhRlAs1jj8puXy3Ezt7NW4pIcad3H+8ETy
7a+Dk9f6Qhb+ZXTWYUwMo4e6zVqMeWVelt+fgLF7w49ev535aR6zueXy4tC7mbHXt2/c81/06KhD
imf46TpEl+k38wLXU19jXbd/rksMQXeu3W5h/nTT5fc3PN7oB9HNDa+H5zsp997kX+bFdlXoZPud
U297Zvehm1jdwlBt08794xVLXrO/UsF8uIQe+FZpNHeafZuO7d2LNU52ZxMdJvZxuSOdzEy2lyj4
SXTTREnyCzdFJhWcYa5Q0bLqex9vJlsX0Q0SInX92cs7xatIo/IEljM7iS4BC/QdctHXWbBErlft
jzMHmzXdeyUUd2iqZtfJ3A1+FN0Ssd2q4Up4qc7rtperCM0uugXyfYhcH6e4Olaap7rvGUM26dgV
fkjTNnczLvrKEm2y3M8Vc4KNm/YFKtmhh3PwAj3qQF9PojKuW/atsTzdLvpaElWQeo8ukQhozKIv
I1FV/LEezngUX6dRFPVaesSWCJN8nUbmSJxHF4yZBs3/BpXsMvQJXTTGqdf0CFSiSmrRhWOaz+gr
RhQED1v3xdztxchyXejaMQof3MlQD9C1Y5Lj6KtFFNRrdPUYxEVfLKLA+PDuVR36UhEFN4SuH1NE
azMSsg4f3r2JxHahZDE+vHtxCH2ZiMLhw7sH59FXiSisPegqMsAH9EUiCo0P79X8jL5EROHx4b2K
K+grRCQCH94re4C+QERC8OG9kofoy0MkBh/eK/gL+uoQicIPVsu7jr44RML8ji4nbR1AXxoicdLo
etLVF/SVIRKJ272Xxq2dKVqOoUtKS3fQl4VIrCl0TWlpAX1ZiASbRBeVhn5DXxQi0Vx0VemHa18p
gubQdaWdWfQlIZLgBrqwNPMKfUGIZEihK0svd9HXg0iOneja0spB9OUgkoSfsW04gr4YRLI0o6tL
I4voi0EkTQu6vLTxEn0piCT6K7rANDGAvhBEMvHQ9BVp9IUgkoorYfNeoC8DkVxcCZtTj74KRLJd
QFeZBprQF4FIOr5MP4e+BETyzaLrDK4DfQmIFDiALjSwS+gLQKREPbrUoDgTR5Z4hq41KM7EkS1s
/jJ9HB0+kSof0NUGxCMVyR4v0eUG8wgdPZFC1n7cgg6eSKUJdMGBtKGDJ1LqMbrkILjBM1lmAV1z
EIfRsRMpVoMuOoCv6NCJlDuJLjv1XHTmRMol0GWnXD86ciKAT+jCU+wkOnAihH3oylPsNDpwIojX
6NJT6g46biKQY+jiUymOTpsI5CC6+BTagw6bCGYUXX7K8OxUstg8uv6U4XYTZDNb9o8bQQdNBGXJ
/nE8gonsNocuQSU+oWMmArPiPIcUOmUisMPoIlTgB3TIRHAv0GUoHzpiIrzon696Hx0xkQYy6EKU
rBEdMJEWIr7kfQKdL5EWon1E0zV0vESa2I0uRpnm0ekSaSKNLkaJ3qDDJdLGR3Q5yuOisyXSRgpd
jtLcREdLpJE/0AUpyTF0sERaQVekJPwMnajYGLokpRhAx0qkmUge3DKBTpVIMz3oopTgPDpUIu2c
Q5eleDw8lWirB+iyFO4oOlIiDUXuw/QOdKJEGlpAF6ZgL9CBEmnpM7o0xVpA50mkJ3RpCvUNnSaR
pmrQxSkSOkwibd1FV6c4l9FZEmmrF12ewtSjoyTSWGQWwtahkyTSWFQWwnaigyTS2nl0iYoxhc6R
SGvX0SUqRBc6RiLNNaCLVISf0CkSaW4eXaQCnEOHSKS9p+gyDY+fpxJVE0eXaWij6AiJDPAQXahh
zaITJDIBulBDakDnR2QEw89RfoDOj8gM9ehaDYMbSBF5Y/Tnqjw9lcgjg2/pd9DZERnjArpcg+OO
kESeGbsDxS10ckQGyaILNqgP6OSITNKOrthgvqJzIzJKHbpkg0mhcyMyywl0zQbxFJ0akWGa0EUb
xCI6NSLTNKKr1j8ewkTkVxJdtv7F0ZkRmce4rZ93oRMjMtAMunD94pQ7UQAj6Mr1ZxKdF5GRDDvN
gavciQIx6pbO79CJgplCF68f3FiGKCCDJt65lztRUAa9S+de7kSBGbPi/Tw6KSKD9aIL2KvT6KSI
TGbIVjNn0TkRGc2QrWYm0DkRmc2IW/pJdEpEhutHF7EXM+iUiExnwB7vneiMiIy3E13G1TWhMyIy
H7qMq7qLTogoAl6jC7maLDohoghYRhdyNeiAiCLhM7qSK/sZnQ9RJLjoUq7MRedDFA0H0LVcCbeE
JBIjhS7mSrglJJEgH9HVXN4RdDZEkTGLLufyuOEEkTDT6Houh9+nEomTQBd0OT3oZIiiZABd0aW1
o3MhihRNT1FuQ+dCFC16bkCBToUoYg6ha7qUl+hUiKIGXdSluOhQiKKmBV3V271BZ0IUOQvost7u
AzoTouh5iq7rrYbRiRBFUAe6sLe6jk6EKIqG0ZW92QA6D6JISqNLe7Nn6DyIokmvdbDoNIgiSqt1
sD+i0yCKKp1ObYmjwyCKqj3o6t7wCZ0FUWRptGiGO8sQSTOJru81PCiZSJ5mdIGv4bmKRBJ1oSt8
FToHokibQVf4Cr5bI5KqD13jBXy3RiTVZXSN5x1Hp0AUcVqcuMh3a0SS3UZXOd+tEck3jy5zvlsj
UuAaus75bo1Ivgl0nX9GJ0Bkg3Zwoe9DB0BkgxpsnU+j+09kBfAbNh6gSqTEY2iho3tPZInryDp/
je49kS06gYW+iO48kS2yuDo/h+47kT1whb4X3XUiezyCFTq650QWgW0p9RLdcyKbXAEVOnecIFII
dGgLp+KIlMIU+gS620R2uYSo83voXhNZpgNR6DfRvSayzTig0DkVR6TYjPo6H0b3mcg+6gv9DLrL
RPb5przQ0T0mspDy6biH6B4T2Uj16rgOdIeJbFSnts4H0P0lspPaQm9Dd5fITl+VFrqL7i6RndIq
6/wTurdEtlJ5lEMturNEttqpsNDRfSWyVlxdnbeg+0pkr1Flhc6X6EQwU6rqnC/RiYBUFfocuqNE
NnuuqNC/oztKZLMhNXXOl+hEUANKCp0v0Ymg1BzDhu4lkeVcFXXOl+hEYCpepc+jO0lkuxn5dd6O
7iMRyS/0neguEtFT6YWeQneRiN7JrnMufyXSQExyob9Fd5CIlpb+kFzoC+gOEtHS0gO5dT6O7h8R
5Z2QWuhN6O4RUZ7cHaX44RqRFlIy6/wGundEtKJLYqFPoTtHRCvmJBY6um9EtEriJ2y30H0jojVH
pBU6t5wg0oa83WDRPSOiDbLq/BG6Y0S04aOkQr+O7hgRbeiRVOjofhFRMTl1vh/dLSIqJufZ/R26
W0RUTM6zO7pXRLSZjDp/gu4UEW0m49mdq2WINCPj2R3dJyLaSnyd30F3iYi2Ev/s3oPuEhFtJf7Z
Hd0jItpOdJ03oDtERNuJfnafQXeIiLYT/eyO7g8RlSK2zkfR3SGiUu4ILfQkujtEVIrYfWa4nzuR
nkTW+TS6M0RU2kWBhV6H7gwRlVYnsNDj6M4QUWlxcXXeie4LEZVzUlih30R3hYjKEXeu6gN0V4io
nA5RdR5D94SIyrsnqNBvoztCROX9IajQz6A7QkTlXRdU6Oh+EFElYur8GrobRFTJpJBC57I4Iq2J
+Sidy+KI9Caizrksjkhz5wQUOpfFEWlOxIctzehOEFFlAj5s4bI4Iu11hi50Losj0t6e0IV+Bt0F
IqqmNXSho3tARNWFrfOz6A7oz22eanv5vuFkbNX4rT9rmmpT6GaZ6UNtU82fH8fXshxoeP9z29QD
F90s/R0PWeg16A7obLk1+7WvbHSxKw9nWO6efZh5eCVWNsy+J9nZZXQTdfYsZKEPoTugq+XEYJ+X
AK9k0hyg1Qxlz3vJMjaZ5DLNMsK+YEO3X0+zLZ6KfM30HMdnWfE2T0W+pv3zLLrFejoRqs5voZuv
oZ/2x/wHOXBoEd1uHe2rCbCzYex9Gt1uDXWHKnQexbRFPBN4357xXj7Db9ZzLWiWscEOdON1E273
iQV08/WSmA6VpvOCUx7rUq/CZbmbN6HNwoTZjm68Vi74+mFe2o136F7o4fSN8FkeG0P3QitHQkT5
Gd14jfwafmQWnOSdaClxRVCY3eieaKQ3RI6c9FjzUtDIzLsyge4N1l5RZZ7Hr6jXLIZIEd12XWQF
jsy8Lov/gg75epvmQT+6R7poDxwhT0suSA8IHpo5Tyx9s+5+FZ9lZw+6V3p4GDjBt+im6yDeIH5o
5lm5uPiynCyP8+1QTjpwflfRTdfABTlDM2f3PLpvqg11SQvzPrpvOggaHjeXWUqJ/kG5iWUvNVpk
ZnmR3w8tBV3lsQvdcLg5mUMzZ8SiBTQpebfzFbyp9wdMbgrdcLSPkodmThu6j6qIfnFRwmN0H9GG
AgZn+QxH6zH5Y9NxJtHdVOOxiiy/2P5hW7DY+tDNxlJwCyr43YIfl4v/pihMax6QSgv2gug5utlQ
+xUNzZwJdF9lE3M0mCcH0H2FaguUmdXLEEbVjU3HyaB7K1eNyiytPvu3I1BkLrrZOAt/Uzk2Hecb
usMyDarN8ndL1xwWBNgSxeaf6PNKpuGKfUV3WR4xZ3f7cMziPSluBcjrErrRMK2qh2bOMLrTshwF
hGnvJ0NNAdLai240SitgaDrO/0F3W47/Cwkzge42yr4AYbnoRoO8gwxNx/l3dMdl+A9QmNZWuv8f
6bbuInUaNDQd5z/RXRfv77Awf0J3HWSX76QG0U3GmIcNTcf5f+jOiybpA19PmtGdx5jxHdQEuskQ
++4Cx6bzEd19sZTPtxer/4DuPsSC76BcdJMR3C/Isek479EBiKRwbWEpX1x0ABB+dyq28yf6f2HH
puPsRCcgjtL1cKX8NzoBiB98pvQHusEId9Bj03Fm0BmIchCdZOR+CXkz4TOkM+gGAwTfXE+giOwv
9QCdY56NE8p+f6S76AarF2RVkXhf0DGIoXwRcUl16BgA/J0NaOFP9GBf/oh3HB2ECMgXa8Us+NZ/
K39v0i38Fl3xB2vlvUQnEd6v6AzX/I5OQr2kr4AiMyfkmYSDBYI6jc4irFp0ghueorNQLuUrn0V0
c1XrRY/IInfRYYSlxw/0Fc/QYSjnZ7m7dTu6+19QJJPhG0Zq9HCUY90+FH4+DI7EhJAfw+jhuJnR
Jyvr8fZi3Sg6D9Xe+gjHtr3wg+58L42LTiQ4F53dVhfQiSjmZ3d3yz79iaPH4jYGL+ragc5uG9vO
J/ARDbqpin1CD8XtJtCZBKVwa2evjqIzUWy352RG0E1VawY9Eks4hg4lKJ1m3NdYdrZYxnMwP6Kb
qhb0G/RyDN0B+g90bqUY+1czGO/npNeim6pUN3oclmbk6k1/qzWUaUHnopbnXFx0S1XSbyZuxSd0
MEEcR6dWhl3zcY0eU7mHbqhSei3vKGLg7uSoHXSrMnwJkk9/ekzlDbqhKs2ix2BZXeho/LuCzqws
q14Y7/UYilXLg5WepuiPcadczqATKy+yJ+GU4nVBt5HTQAEdRo/ACgbQ4fg1gk6sgll0OCp5/K4F
3UyVbqAHYCWGvf+dQedVyTl0Oip5mxOdRjdToevo8VeRYbf0k+i8KmpFx6PQBU+JZNDNVOgIevhV
NoHOxw8NF78Ws+lXurcfpBYtl9Fln7hyxtEB+XEenVYVFh3dsuwpEIsWF9xGD75qDHorpO97ylVv
0Akp5OW8Fot2l9Hu0+ltbqEj8u4jOquqXHRE6rzxEMcNdCPV8f6ZD4wxj1e6riQuchmdkTp1HuKo
QTdSHb8H0nFwlmfAH80+dEbqeNllxp4j5DXaldj8wWnAH03nHTokdTyk4aLbqIyun1qZODhPo3Py
wsgPAoOpvqbBnrk4/afiDBqcRvzRtOgmVn0DkB3oJiqTRQ87b9AxeeLtxS1cGzonZWaqZmHP5rhn
0cPOGyP2eH+GTsmbK+iclKm+1c91dBNVWUSPOo8uooPy4ho6JY/sObalahTf0S1U5RB60HnlopOq
7hd0Rl7Z87w6XSUJe+bivG6sBdeETqo6L+sztGDY94Ah3KyShDWbaxmwkmuVAZ9dabxNzxbGrDQM
K1EliCy6gapodhRgJeioqkMn5J0RU5siVLuRWbPjjuZfohfTfs2MCUsMV9lzPFOVIJbR7dMkB538
gM6qmlfohHxAZ6VMV8UYrJmL03sPqc2032f/HjohHw6jw1LlYcUYrHmyMeBjqw2azyCZM6/pGPQ5
YFgHK8awE908VQxZFrdC891gZ9D5+NGFTkuVytukJdDNUwU93nw5gE6rskfofHxBp6VMxRT2oVun
iM7nNmyn+UfpJv1Et+i9UsUdAtCNU6UfPdz8cdF5VWLG577rxtB5qVJp37hGdONUMePz6XVaf2n0
Ezodfz6i81KlrUIIz9GNU8Xj4VS60PpbDGO+DlphzRvkSj9PDfh+QgjDnjb13g3kKDodn1x0YIpU
GuQGnRcQSho92HzS+i5k2NOR3r+DFF0YWxbAGrKL1AZ0YBUYsovUhjl0YqqU/5xD6xuHSAfQg82v
DnRi5XnZQlwrl9CJqbKzbATWLIAdQQ82v3rQiZU3g87Gr7PoxFQp/0m6NeuATftZqfOV+RWdjV/W
PLeW3xXxDLppipg26e44j9GRlbcDnY1vtsxElV8Eu4humSLz6KHmWzs6svJMOItps6voyFQpuy0i
umGqGHF+0CYaP24a9zNI/x17RNlfJgCNbxti9aKHmn/oyMpDJ+PfM3RkqrwtE8BTdMNUqbYVroa0
PXnAlHMwiliz6cK7MgH0oxumyhtf40IL2q5ZbEYn499tdGaqlNv6x5pdJ6qdYqGhWnRm5ZxBJ+Pf
OXRmypQJIIVulyrmTRQ7B9GZlZNEJ+OfNXNRS+2lA0A3SxnzJor1PfD3bfi+qabxKwzBnljefwML
XdsJpF/Ryfhnz0AvvZGSNSvdDXwj5HxDZ1bOJXQyAaAzU6b0ETo/o5ulDHqgBaDtTPEudDIBoDNT
JlWy+zPoZimDHmgBPEFnVs4kOpkA0JmpU7L7Q+hWqWLeNy0ab2lo3jctjvMdHZoyJXfituajHhML
XdsJFINOpV3nokNT5mOJ3tszF7mAHmgBaLvI4xo6mQA0P8tOoMsler8b3ShljDoUcNU0OrRyzqOT
CUDbDweE21ui99q+wBHOxDv6DXRo5YyikwnAnjv61RK970U3ShkTf6N/QodWjmm7uue56NDUKdF7
a06IN2+DYkfjvaRuoZMJwJpp55KLQO15njHxPfoLdGblPEUnEwA6M4UuWt179EAL4D06s3Leo5MJ
AJ2ZQt+2dd6et2tGFno3OrNyTqGTCQCdmULbj1Q9gm6SQgZ+vabt7j+GnaWaZ9M9bfs+qKfQTVLI
wELX9p1ILzoZ/2wq9O2ftWg7kiS4gh5q/ml7JlMPOhn/rqAzU2lb79PoFilk4IcYP6EzK8e0E6hz
jqMzU2nb06stp7Tk/Ykeav5pe5zq1fB9U+0PdGYq7d7ae3SDVLqPHmr+uejMyvmOTsa/MXRmKm09
IdymCQoTf1eiIysPnYx/U+jIVBrb0nl7vl1bMvHQAY3/Dpv3CmMWHZlKWw9Jf45ukErmfad6Fh1Z
eeZ9p2rTdNS2OZT76AYphR5qvh1AJ1beIDob39CJqbWl89Ycx1Rg3FEtWXRi5dWhs/FL459BMmz5
aWXN4fAFj9GDzS+Nj/Q+jM7GrwZ0YmoNbO49ujlq1aAHm18a/6w0br+el+jE1Lq9qfOWPc5MoAeb
X+jAKkFn45e251XKsflYpkZ0c9RaRA82n06gA6ukC52OT9ouMpRj86IRbQ8CkQQ92HzS+uXnj+h0
fELnpdiDTZ2359y1FdPo0eZPEp1XJXvD90+lRnReim2eQ2lCN0exm+jh5o/WT5uG/Q66hM5LtU29
P41ujWKJgKMEBB1XZWYtgtX66Uj65dH6liGBWVu7a75Twid0Pr6k0HGpNlLcexfdGtVOoMebHxl0
WpXNofPxw7IXyUtbNuRGN0Y5o/aeuI5OqzKj9p7Q9vxpaYrno+z7M2fUj3R0WNWY9CPdtmnnpaWm
ot6fRzdGOZM2RtH8J7pZxzJZ9xN96V1R71+hG6Pe7sBDRTlt93RfM4NOyLt76KzUK/5lVYNujHpj
gceKctq/EjHoHYY9h4OvK354tWydf14q8FhRzYCbkDmPRzbtar6mqPuaT+tK0Y4ec179gE6qOnMe
j9BJIRTNldo3Q7G09Bo95rzSeNOJNfvQGXn1CZ0UwrWN/qObgtCBHnReoYPy4iw6JI/2ooNCeL7e
ffteo+c1okedNxrvC7mhLXw/lUDnBLGx9YQB0z0SGHLg72F0Tl4YMu8+ic4JYma9/zadjb7BjN3d
DXnaOofOyZNadEwQrev913oDE3muBR8x6nSjU/JmCp2TJ+iUMDY2DPgN3RQMI45gM+WFSD06KA9+
RIcEsh6AfQv9VxgwOKfRGXllws5xpvzRFG09ADt/uiwt7UGPvOqMOfnTgA2ljPmjKdr6ipkH6JaA
6D8dV4+OyLthdFZV9aAjQll/kRxHtwTlK3rsVbMTnZB3aXRW1bSjE4K5sxYBuiEwQ+jBV813dEI+
6H6Qw1t0QDDf1iJANwRnFD36KnuPzscP3d+wofPBWVsaZ8iaDBnehRo60pk1Taz3hpst6HhwkqsR
DKAbAqT1kS230On40xS+xxK56HhwTq9GYOcK4BVaTyF9QKfjk86fCf2BDgdofjUDix9qtF4H+xid
jV8H0YlVYNK0pmhrr5HH0A1BOowegeWZ9Qs9byR8pyWx+ma2tjTOwh3jiuxAj8FyHqGT8U/f3fLR
yWCtLo2zccO8Ddqu3XTRyQRwFB1aGffRwWCtFrr22wnL1Y0ehaXdROcShKZ76zaicwFb3elrAd0O
sLvocVjKMXQqwXwL33MJrNwqrsjkSgzoZqAlw40iOYz5bG0LHf9qNqBDQftciMHihXGrjqNH4nZH
0ZkENYNOrgRrP9pas7LvPgtdw89Vzf05pd9bjMvoSOBWFjj0oZuB1x9yKAln8OZe2m0IO4JOBK+2
EMRFdDM0cBE9Gjczei+UOXR6W9i6rUqR5kIQT9DN0MACejRutojOIxS9jku3dUfIYitvPf9EN0MH
dejxWMz0NcnH0AEWOY8OQwcr9zHOVeQ9Ro/IDcfRWYSl00pY8z4YkKEQRR26FXr4C3pIrrm7jI4i
NH32fp5DR6GHQhamLs0QbD7kkBLGiMPWqtBlzbtRe3FJVFjsbvc3LRs02SClBp2DEF/QMRb8NzoG
XRQKfQjdCl0cQI/LvIhs9zOLzjGv3volcWsG8nGY/S5HpE/okek4/0BnIIoOHxC8Q4egjcIib5s3
2dnin+iheddFRyDMTXSWtn+EXux9Pg90IzSygP72Kko/o16As7yEDkAj+dcg/KalCPjolmidDoad
ev+K7r5OLrDQt4Bu/xy1FQ3/AczS+FVHQuXfKJ1AN0IvE7ix2Y/uu3D/gGX5d3TX9ZIf1fx4bTPY
dHEG3XMJ/g2U5f9Hd1wz1x3jzv2RD3RYYDQ/OfgvSJa8n2+RX/bJVYJb7UWMzWgsiNvuPwFZ/ju6
09rJ76Fk9xkWJZ0OPdR8i+47X/UnUx9Bd1k/33Ox7EQ3QkNXVS/VTqJ7LJHq9+l8r1ZCLpcsug06
+kXpLFJ9tL8rOqW0zh+iu6ulXDBN6Dbo6Yi6ofl71L82uKCwzk3fnUeSWNRWY4nTompofkL3VL5W
VUuL6xPormoqxk98ylL0Qv0Uup8quH9XkuU/zN0OX7Jcoc+i26Ctjr/JH5r1tjxQvVJQ56/QndRX
n/VnqVb0XPbQvOGiu6hMQvbje32UX12E1cV9JyqSPDqjuOq1rGW5X7NZ9DczgAbHcdFt0Np3idtA
j9v2MJWVWOd8S1zRU+47UU1C1nEEUV30WoF7R1KWDZyFq+w5C706Kb/Uj9u5ceGMjD+bx2bQ3dLe
j9x3woNUg+ih2WjLZPt2vwmv85/RXTLAZRa6J2cGRI7M+jZ0f5AWngot86e/oDtkgrcsdI9mGoWV
+QV0X9BSk8LKfJJnq3nSxEL3LHlSSJlHb8OoADrEHK08eRXdEVNMOY3oJhik9XjYkTnQi+6DLtxM
6DIf5N3cs1pnHN0Eo1y9FGZkPuFy42JzYSY+zvKrSz+uczsOvxLDwUbmSNb845BF6+gOlmVs0La1
RmE9cD6im2Aet+mc35E53mbna/Pq0gdiPrPsa+GDkW8fnBfoJphp6pHn8Rl7M+Oim6u12cyI5yqf
7ue9PIiUcwDdBGMtPtvfXm1ctu9v4oyRF6df7676B7OhhrfyoOLOn+gmmG3o2euGkvf2e3d+Ts6j
W2eWxYmaF42lwoydH2xLu+jmGW3B2YNuQhS4qeaJ5Fy2P5O5n206eHpokUdRBxeffzfTlL2fyRzK
tiV7WlMuukFR4HK3Z6LoW3asX49JZAHH6u8riCzBbd2JLOAcRLeAiKTj+Q1EFnBq0S0gIumcaJ/v
R0R5zmF0C4hIOp7IRGQBpxndAiKSznmAbgERSefwCyui6ONhqkQWYKETWcD5gG4BEUnncKcjouhj
oRNZwFlEt4CIpHO43zhR9LHQiSzgLKBbQETSOS66BUQkHQudyAIOjxogij6HZ/kSRR8LncgCfHQn
sgAn44gswEInsgALncgCzi/oFhCRdFwCS2QBFjqRBfj1GpEFWOhEFuAOM0QWYKETWYCbQxJZgIVO
ZAEWOpEFeFILkQWcq+gWEJF0PGSRyALOELoFRCSdM4tuARFJ51xHt4CIpHPeoVtARNI5CXQLiEg6
pwfdAiKSzjmIbgERSec8Q7eAiGRbdurQTSAi2VznLboJRCSb6/Sjm0BEssWdnegmEJFsKWcPuglE
JNuQ04JuAhHJ1upcQjeBiGQ77exHN4GIZOtxnqCbQESyJZ1P6CYQkWx1zjS6CUQk2wXnBLoJRCRb
xomhm0BEsrWw0Imi7wALnSj6vjoOuglEJFsDC50o+naz0Imir91xXHQbiEiymOPE0W0gIslyhc7j
VImiLlfoPGWRKOoch4evEUVertBr0W0gIrncXKFPoRtBRHIt5gqdR7UQRdx8rtCz6EYQkVzXc4XO
jd2JIm5vrtD/RDeCiOR6liv0F+hGEJFcY7lCH0Y3gojkepkr9EZ0I4hIrgO5QucWM0QRd4uFThR9
47lC584TRBF3j4VOFH2xfKG76FYQkVSFQt+HbgURSZWvc2ce3Qoikmm5UOjceYIo0uKFQp9AN4OI
ZOooFDo/SCeKtNlCoY+hm0FEMk0UCv0muhlEJFNdodCfo5tBRDLtLBT6J3QziEim54VCP4luBhHJ
9KlQ6BZ+vjbjPEE3gSCWl9EtQGi0tNBbcr0e70G3gpTrr3ec+gS6FeoVlrrb9/law0q3+/qt/Otu
rdT7levuHEK3RLnVjrvodij14Iuz7iHPjLZFenjjsr9BN0a11X6n0O1QacbZ5Gga3SBSoOfKpqv+
DxfdIKXiq92eRTdEoZvOVl1JdJtIsv57Wy/6MasOC59f7fUEuiHq7HJKuJtx0e0iaVKvSl1zx6Y/
7+9W+9yEbogy55wynlv188UiPw2Xu+Q16KapM2NZl1P/cso7b9OfeFv0dla44u/RrVNmbLXH39AN
USPtVNHNOfgo6XhV5XqPoluoyqnVDj9GN0SJ/mp1nnP8J3QrSZBkV/XL/TdLfrC9WO3vWXRDVHjk
oc5z+jgxFwHxjLerbcmU3PnV3lqwBtY97/HK5zxpRbeWQileG1PNKXRjVYhZU+hn6r1f+pwRe95D
RI7b3+7rWh9HN1iBtUKP/GL3OV+XvuB9M7rRFMC7j76vdD+6zfKt9xXdENlO+C90x+m84KLbTb4s
3uwLcJ3Po5st33pf59AtkWw0SKHnNEygW06eHZwOdpHb0Q2XLrXR2Yh/onsmYKHntHSgG08ePKj2
zry8Xei2yxb/n6Le7kS3Rq4DwSvdGalDt54qc+83hri+Uf9LvnNzd4dddIOkmvkSbBSsmOS3rPqq
/RTm0r6I+GpId3hrj+sjvnagJsxwcGKDVn3XaIyO7nthLuto1JdMJO6W6PV+dKvkWn7vexxs0t4d
9Yc806T6A71OWdcV8amptW2et/k94netjqCz72sG+i1ZHG0At/dsuIvZGfFH2Nwfwn+W7fwFdNsk
6wn1Uz3vfK+L7gTlLmTYv9n1WXQXpGur1P8d6NbJdihspTvOUW4UjbU31PRbQcTfMuVVCeluLbqB
sj0MX+rOk8inpK3Tu8Jfvj/QnZCv9m7VFCK/DYX7VECpO3eSLroj9km/EXDlblkwqXrKSxC7Iz/j
1NEgotSdc/y9rlLPDhEXLfJv1HLiXlcDR3+ionVcSKk709nI/1XUQ89RIdfrbOTfqC2tnYbuySS6
rfJNnRRT6s7Zt4vovkTcL70XxVyqASumUSf9RHLMgr98yU4/iVRysmYe3ZnISr31sPmbJ40z6L6o
cKb6LNxmEV8nV5AMt7KqWGyyh0c2CteaEXaF+qL/ezTvuf9k/mrDlxzJY8JKPfcQ32/BfK4yy2Im
31bctaPMW/8SKJ0WdLtVyPrbTq6KvkELfvMo0NEvagolr96C7aLyfgwaUJcVd6gxoaXuOMeTC+gu
mW1iVyz8Vdhgyd18aT7M4n8Llgrm9Ir7rb6ifTDBX+yBdGR9bMztqcwtuZuHXdw9bcd7YoHTcmuu
ZCxYmiFUPLlD6K3csWYKLpfdudBZWRJVz4DwUnec0awVP34EWE4Mii5yx+nsRXdLFR9rZMo77qK7
ocaUjFJ3YpPJiO9WFF7tN3+HLnjTGPkPzte4YpYNOo4tfxjT4Z9/yhR7L+/spS2nMyNSMu+yYhVc
QY+42eSv6L6oMnRLTqnn7Jjj8rnN4lPPZdzJ84avozunjq8lr9VEfevIDe6f0ko9d5vJpDkbX5BK
7g+1rWNFjyO+J1qxHr9LXqu59R3dJVWWvZ65G8zJUwkX3UWs5rFh8RNvGw5Y9JGRe0d8fvUWHTFa
F+ZEAA9it+7PovsIkTo4KOxTotKsOt++V06GDRat+GoNvy9ZNbHjWYseMZfcdP9F2ZE22jJtvJKo
vDFqyTv1gniL7GGZd2J/b/SrPVfjN2Q+rK+6Ydd3Br0ysxy2Y6HcWpTi18uV1Lm/t9VFd1aOeLp/
UkGN57yI/h/MTcHekJznGLqHSp25pmSQ5sUadk5E6c+o2zr3SvLv8Q19NZa9zeiXn+m1KI3G6lJK
nuDXdb6fM//m/qHn5jk1t/EVo2fQPVYdcMCz4H26jO6nYomLCgdtXqxxsj/RYeA9arkj0T/Zpzit
QetWHcp9/1tk3LZoO8IcsB5Y7Mie5KyL7rs3qdqx/SdV3sVXnbVmJde6eVGb6HlxCt1b5XrlLMmu
Ltb5omZmVtcPY5avnq77PAqo8IJddk3AFYg4aciHL/Z8M7Cmoxs0nFfETk5mkmldHuiXU63JzGQ7
qsLzRrKaZKFS+l/Kc/5qzZrYDT2y32l4EBt482vdRDNmnaf7IT1zoeVoDFngK07ZNSe8Gv9jSNZz
6H4DLBxS9G7dg9jI5OexqXTKld3p+FBt028HGu7hy3tVg11LY9ZkUXlftG1SrmAWMjNXUSwWm54c
zGQP1s5+ELFO+Xt8/vrepguv338a0eDmvcVf3rroIQAxL3g3PV/sm5TLq0GP9SpydR8buTj5ZnBP
JluXnDjdmjPfkRPPW15edvP/TOX/TXPuPx1OTCXbLmRaLk1+utie/3/R7a/MRV9+DMWTcFv91cqT
wjUvhUizY2PirSa+oHO3clJO3p4JVI0VJ6ht4crb+MgP6yblXHTiNnuKvvrqvUVnvsa2Sbnb6MCt
ZtuWHUPj6MSL/IBOQ6U4Om27TaOvv1oy9zAMoN6ivT1eocO2nE0v0XsFHwwowLQt6455QwcbQI8A
ZbR6at+wy461x/qtl7GNLV+s7UcHXZYNJ1cuoEOmY+gxoEQvOuZKzqbR8Ug3iM6YbNjPbEjlV+dB
PHXREcnFG7oO0KNAtuVd6IQ9iPbz+zd0vJTzGj0M5DqEztebkQg/v3NRnB5c9ECQKC3l/G4pJnXd
+Sg03tD18Bw9EKRJ7UBn68uf6Lzk4C90XUR1g5nP6GD9qo/k8U36vtm0zSf0UJBCw4Vw1V2J3lLF
FDpTWhfBiSCDfpxvdjxqX7VNohOldV3owSBaxzA60hAuodMTaggdJxWJ1kLYZdM/lYrSW/Uj6DCp
yF30cBBJ920IPeiMzF/eNDpK2uQmekAI04PfEU6E0Xl0kGIgt9ulElz0iBCjVc+PUYN4HIW3nhPo
FGmL2+ghIUI8WjO839B5hncFnSFtZf5bneXo7W5g+n7cSXSAtM0welCEZdw6OC8MXysXjfmSiJlA
j4pQYGepyWbyBPxOdHhUwl/RwyKE5DF0ehKdNXVZLD9P1VMGPTCCSpi63NWrc63oiAPh1yyactEj
I5BWG17VfjRwrnQeHRqV8RU9NALoaECnpshzFx21XzfQkVE5xp3QlIrWi/PKDpi1BQ3XyuhrN3pw
+BN/gw5MsRYXHbkPJ9FpUXlN6NHhgxu99THVnUKn7tkYOiqqoB49PDxzf0BnBdKNTt4jE/f3scgg
enx4s2zz0R+/odP34hI6JarMhE+m3D/QKYHpv+KhAx0RVXEOPUSq24POSAO6l/ooOiCq5iB6jFTx
MzogPdzV+msXfrWmP613lVp+jY5HIxrf1e+is6Hq9J2Pc1vQ2Wim20VfktL+RAdDXmi6rNrlRO52
p1z0ZSmBM3FmuIgeKKWkbFsF59UB/d6TcCbOEM/QI2WbjjvoTDS2X7NS50ycKXRbHzd0FJ2I5iaH
0JeoWJQ3AYmYV+ixUix9ER2HAT7qsw08Z+IMos8dYu9ZdBaGGNVkwymetWYSXb5X7Yn6JlEinexF
X648G/b7iZAx9HjJ67+HjsEwd/GnM15AZ0D+/IIeMe5DdARG6sZuQ7OA7j/5dAdb5kO30AEY6wly
guUTuvfk117gcElfQ/feaMNp1IWbQXedfDsGK3POwIW2G/Rnmh+zGOgPzFi5zBk4Efr6l9VfO57Y
YCTAb72UjRs+yvKD6q+T0ugeUyDKX6anz6G7HDFH3im9fvzJZagLSofJWDu6vxHUqHAjmh/RnaWg
FpQNEj6zSzOo6OO2ZnRHKbAGRWWe5hfMMjUoed3Whe4mBafky/TeE+huRt6A/AvJB3eTyf8y/Sqf
2dUYlDsHz4/WzPZYbpknuAROnfNJiVeSD+6Gm5A3NuIZns+lmLSJOR6qYTpp27wnOAGHcETK/hR8
cDeflG2l3AwXuqL0ZcR/ycoH9wi4LnxYtHJPV6zJVrEXlMdkRcHvYgeFW9OH7hE57SJv63xwj4YW
gWWe5oYSujgq7Nf639BdITFmBQ2IOH+ZayUm5t36N3Q/SJC/CCnzJGds9CPg3fppdB9ImIehR8PQ
e3QfqIxHIWfmeDBLhIQbC26Wq9l1NtAWYjeaJ+jWk0BhZt57uDJGf8d7Al5dnqgYLZcCjoMhHmpu
iv1BHtu+cxVzxBwOMApS/XxkN0lfxvdS+AZ0m0kw3zPvy708HtE8402+LvIYur0knL/dn6f4w9xU
O7z/XL+KbitJ4H3Ne+sLdFsplG/N3i70P9ENJQn+5e3iD33m8jfz9XV72NX/B3QrSYrn1S89p9+i
o71arfegW0iSpKtVeSe6hSTUyf4K0/B8sxZZXypWeSO6eSTBSNla55u16DpQ5pq7vVfQTSNppp+5
Ja75TnSzSKJSD+/x+yPoZpFk09mt93WeyxJp2x7eU1kui7HDlmf4f6HbQ1Jtenjn7JtdBjZqfRe6
LSTZ+kG8Q93W7v32v1/a1geaaTuuAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTExLTE0VDIwOjEx
OjAwKzAzOjAwJzHhVQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0xMS0xNFQyMDoxMTowMCswMzow
MFZsWekAAAAASUVORK5CYII=" />
                </svg>
                Login with Discord
                </button>
                </div>
            </form>
            <div id="copyright">
            <p class="psecond text-muted">© 2021 Nullified. All rights reserved.</p>
            </div>
        </div>
        
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        auth() {
            this.$router.push('/auth');
        }
    },
    data() {
        return {
            details: `${process.env.VUE_APP_NAME} v${process.env.VUE_APP_VERSION}`,
        };
    },
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Work+Sans:300,400,700,900');

* {
    outline-width: 0;
	font-family: 'Montserrat' !important;
}

body {
    background: #23272A;
}

form {
    position: relative;
    align-items: center;
    height: 100%;
}

img {
    width: 150px;
    margin-bottom: -5px;
    display: block;
    position: relative;
}

img:first-child {
    width: 150px;
}

.text {
    padding: 25px 0 10px 0;
    margin-top: 0;
    opacity: 1;
    transition: 0.8s ease;
}

.logo {
    position: relative;
    top: 0;
    opacity: 1;
    transition: 0.8s ease;
}

h1 {
    margin: 0 0 15px 0;
    font-family: 'Work Sans' !important;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    user-select: none;
    opacity: 1;
    left: 0;
    position: relative;
    transition: 0.5s ease;
    text-align: center;
}

.formContainer {
    text-align: left;
}

.formDiv {
    margin-bottom: 30px;
    left: 0;
    opacity: 1;
    transition: 0.5s ease;
    position: relative;
}

.formDiv:last-child {
    padding-top: 10px;
    margin-bottom: 0;
}

.titleText {
    margin: 0;
    font-weight: 700;
    color: #aaa;
    font-size: 15px;
    user-select: none;
    text-align: center;
}

.subText {
    margin: 0;
    font-weight: 500;
    color: #ddd;
    font-size: 12px;
    user-select: none;
    text-align: center;
}

#container {
    height: 100vh;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('https://cdn.protonrp.com/img/backgrounds/fivem_vehicle_wp.jpg');
}

#copyright {
    width: 100%;
    margin-bottom: 0 !important;
    overflow: hidden;
    position: relative;
    bottom: 5px;
}

#loginContainer {
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

.discordContainer {
    padding: 45px 30px;
    box-sizing: border-box;
    width: 400px;
    margin-left: 0;
    overflow: hidden;
    opacity: 1;
    height: 431.5px;
    box-shadow: inset 0 0 0 3000px rgba(54, 57, 63, .75);
    transition: all 0.5s ease;
    backdrop-filter: blur(10px);
    background-size: cover !important;
}

.pictureContainer {
    padding: 45px 35px;
    box-sizing: border-box;
    scale: 1;
    position: relative;
    z-index: 2;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 700 ease;
    backdrop-filter: blur(10px);
	box-shadow: inset 0 0 0 3000px rgba(40,43,48,.9);
}

.login-form {
    width: 65%;
    right: 0px;
    position: absolute;
    background-color: #9999;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formButton {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    bottom: 0;
    display: inline-block;
}

.psecond {
    text-align: center;
    padding: 5px;
    font-size: 14px;
    color: #6c757d !important;
}

.button { 
    background-color: #5865f2;
    border: none;
    color: #fff;
    justify-content: center;
    width: 100%;
    border-radius: 28px;
    font-size: 20px;
    padding: 16px 32px;
    line-height: 24px;
    cursor: pointer;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    transition-property: background-color,color,color,box-shadow,-webkit-box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    font-family: "Whitney","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.button:hover {
    background-color: hsl(235,86.1%,71.8%);
    color: #fff;
    box-shadow: 0 8px 15px rgb(255 255 255 / 20%);
}

.discord_icon {
    margin-right: 8px;
}

svg {
    width: 24;
    height: 24;
}
</style>